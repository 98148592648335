<template>
  <v-form ref="form">
    <v-layout row wrap>
      <input :accept="accept"
        required type="file"
        style="display: none"
        ref="fileInput"
        @change="handleFileUpload" />
      <v-text-field
        v-if="!isFileUploaded()"
        @click:prepend="onFilePick"
        persistent-hint :hint="tempFile.size | fileSize"
        label="Selecionar arquivo" readonly
        @click='onFilePick' v-model='tempFile.name'
        :loading="isUploadingFile"
        :error-messages="fileError"
        prepend-icon='mdi-paperclip'>
      </v-text-field>
      <v-text-field v-else
        :value="filename || 'Arquivo'"
        @click="goToFileLink(file)"
        class="fileLink"
        prepend-icon="mdi-paperclip"
        append-icon="mdi-close"
        @click:append="removeFile()"
        readonly>
      </v-text-field>
    </v-layout>
  </v-form>
</template>
<script>
import fileSizeFilter from '@/mixins/fileSizeFilter';
import _ from 'lodash';

export default {
  mixins: [fileSizeFilter],
  props: [
    'label',
    'allowedExtensions',
    'extensionErrorMessage',
    'uploadFunction',
    'isUploadingFile',
    'uploadError',
    'accept',
    'file',
    'filename',
    'removeFunction',
  ],
  data() {
    return {
      tempFile: {},
      fileError: '',
      defaultExtensionErrorMessage: 'Extensão inválida',
    };
  },
  methods: {
    onFilePick() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const fileToUpload = event.target.files[0];
      if (!fileToUpload) return;
      this.tempFile.name = fileToUpload.name;
      this.tempFile.size = fileToUpload.size;
      if (!this.validateExtension()) return;
      this.uploadFunction(fileToUpload);
    },
    validateExtension() {
      if (!this.allowedExtensions) return true;
      const fileExtension = this.tempFile.name.split('.').pop();
      const hasValidExtension = this.allowedExtensions.includes(fileExtension.toLowerCase());
      if (!hasValidExtension) {
        this.fileError = this.extensionErrorMessage || this.defaultExtensionErrorMessage;
      }
      return hasValidExtension;
    },
    removeFile() {
      this.$refs.fileInput.value = '';
      this.tempFile = {};
      this.removeFunction();
    },
    goToFileLink(file) {
      window.open(file, '_blank');
    },
    isFileUploaded() {
      return _.get(this, 'file');
    },
  },
  computed: {
    fileHint() {
      if (this.tempFile.size > 0) {
        return `Tamanho total: ${this.tempFile.size}`;
      }
      return '';
    },
  },
  watch: {
    uploadError(val) {
      this.fileError = val;
    },
  },
};
</script>

<style scopedlang="less">
.fileLink .v-input__slot .v-text-field__slot input {
  cursor: pointer;
  color: #1976d2;
}
</style>

