<template>
  <v-dialog
    v-model="dialog"
    width="650"
    persistent
  >
    <v-form v-model="valid">
      <v-card>
        <v-card-title
          class="headline green darken-2 white--text"
          primary-title
        >
          Informar Licença
        </v-card-title>

        <v-card-text>
          <v-layout column wrap>
            <v-flex xs12 class="elevation-1 px-3 py-2 text-xs-center">
              <file-upload label="Arquivo da licença"
                           :allowedExtensions="['pdf']"
                           extensionErrorMessage="Insira um PDF"
                           :isUploadingFile="isUploadingFile"
                           :uploadError="uploadError"
                           accept="application/pdf"
                           :file.sync="file"
                           filename="Licença"
                           :uploadFunction="uploadFile"
                           :removeFunction="removeFile"
                           ref="fileInput"
                           v-if="fileUrl === null"/>
              <div v-else>
                <v-btn
                  :href="fileUrl"
                  target="_blank"
                  color="green darken-2"
                  class="white--text"
                >
                  Visualizar Licença de {{translateLicenseType(license.license_type)}}
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="red"
                      small
                      icon
                      flat
                      class="remove-license"
                      v-on="on"
                      @click="removeFile()"
                    >
                      <v-icon>
                        mdi-close-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Remover Licença</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="licenseData.process_number"
                label="Número do Processo"
                :rules="[validationRules.required]"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="licenseData.number"
                label="Número da Licença"
                :rules="[validationRules.required]"
              />
            </v-flex>

            <v-layout row wrap>
              <v-flex xs6
                      class="pr-1">
                <v-select
                  :items="orgs"
                  v-model="licenseData.emitter"
                  :rules="[validationRules.required]"
                  label="Órgão Emissor"
                >
                </v-select>
              </v-flex>
              <v-flex xs6
                      class="pl-1">
                <v-menu
                  :close-on-content-click="false"
                  v-model="showDatePicker"
                  full-width
                >
                  <v-text-field
                    slot="activator"
                    :value="licenseData.due_date"
                    label="Data de Validade"
                    persistent-hint
                    :rules="[validationRules.required]"
                    readonly
                    @focus="focusDatePicker"

                  />
                  <v-date-picker
                    v-model="date"
                    no-title
                    locale="pt-br"
                    @change="setDate"
                    :min="format(new Date(), 'YYYY-MM-DD')"
                  />
                </v-menu>
              </v-flex>
            </v-layout>

          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              color="red darken-1"
              flat
              @click="closeDialog()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-2"
              class="white--text"
              @click="save()"
              :loading="loading"
              :disabled="!valid"
            >
              Salvar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import fileUpload from '@/components/base/FileUpload.vue';
import format from 'date-fns/format';
import licenseService from '@/services/license-service';
import translateLicenseType from '@/mixins/translateLicenseType';
import fileToBase64 from '@/utils/fileToBase64';

export default {
  name: 'LicenseFormDialog',
  components: { fileUpload },
  props: {
    dialog: {
      default: false,
    },
    license: {
      default: null,
    },
    file: {
      default: null,
    },
  },
  mixins: [translateLicenseType],
  data() {
    return {
      licenseData: {},
      fileUrl: null,
      date: '',
      orgs: [
        'IMA',
        'IBAMA',
        'Orgão ambiental municipal de Maceió',
        'Orgão ambiental municipal de Arapiraca',
        'Orgão ambiental municipal de Marechal Deodoro',
        'Orgão ambiental municipal de Delmiro Gouveia',
        'Orgão ambiental municipal de Jequiá da Praia',
        'Orgão ambiental municipal de Rio Largo',
        'Orgão ambiental municipal de Pilar',
        'Isento',
      ],
      format,
      showDatePicker: false,
      validationRules,
      valid: false,
      uploadError: '',
      isUploadingFile: false,
      loading: false,
    };
  },
  methods: {
    save() {
      const vm = this;
      vm.loading = true;
      licenseService
        .update(this.license.id, this.licenseData)
        .then(() => {
          this.closeDialog();
          this.$store.dispatch('person/fetchPerson', this.person.id);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'primary',
            message: `Licença de ${this.translateLicenseType(this.license.license_type)} foi atualizado com sucesso`,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadFile(fileToUpload) {
      const vm = this;
      vm.isUploadingFile = true;
      vm.uploadError = '';
      fileToBase64(fileToUpload)
        .then((data) => {
          this.licenseData.file = data;
        })
        .catch((err) => {
          this.uploadError = 'Erro ao realizar o upload';
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isUploadingFile = false;
        });
    },
    removeFile() {
      this.licenseData.file = null;
      this.fileUrl = null;
    },
    closeDatepicker() {
      setTimeout(() => {
        this.showDatePicker = false;
      }, 200);
    },
    closeDialog() {
      if (!this.fileUrl) {
        this.$refs.fileInput.removeFile();
      }
      this.removeFile();
      this.$emit('closeLicenseFormDialog');
    },
    focusDatePicker() {
      setTimeout(() => {
        this.showDatepicker = true;
      }, 200);
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    setDate() {
      const formattedDate = this.formatDate(this.date);
      this.licenseData.due_date = formattedDate;
      this.closeDatepicker();
    },
  },
  computed: {
    person() {
      return this.$store.getters['person/person'];
    },
    office() {
      return this.$store.getters['person/office'];
    },
  },
  watch: {
    license(newValue) {
      if (newValue) {
        this.licenseData.emitter = newValue.emitter;
        this.licenseData.number = newValue.number;
        this.licenseData.process_number = newValue.process_number;
        this.licenseData.due_date = this.formatDate(newValue.due_date);
        this.fileUrl = newValue.file.url;
      } else {
        this.licenseData = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .remove-license {
    right: 25px;
    top: -20px;
    background: white;
  }
</style>
