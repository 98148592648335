const translateLicenseType = {
  data() {
    return {
      licenseTypesTranslations: {
        receiver: 'Destinador',
        transporter: 'Transportador',
        temporary_storage: 'Armazenador Temporário'
      }
    };
  },
  methods: {
    translateLicenseType(licenseType) {

      if (this.licenseTypesTranslations.hasOwnProperty(licenseType)) {
        return this.licenseTypesTranslations[licenseType];
      } else {
        return 'Opção inválida';
      }
    },
  },
};

export default translateLicenseType;
