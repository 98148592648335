var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "650", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline green darken-2 white--text",
                  attrs: { "primary-title": "" },
                },
                [_vm._v("\n        Informar Licença\n      ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "elevation-1 px-3 py-2 text-xs-center",
                          attrs: { xs12: "" },
                        },
                        [
                          _vm.fileUrl === null
                            ? _c("file-upload", {
                                ref: "fileInput",
                                attrs: {
                                  label: "Arquivo da licença",
                                  allowedExtensions: ["pdf"],
                                  extensionErrorMessage: "Insira um PDF",
                                  isUploadingFile: _vm.isUploadingFile,
                                  uploadError: _vm.uploadError,
                                  accept: "application/pdf",
                                  file: _vm.file,
                                  filename: "Licença",
                                  uploadFunction: _vm.uploadFile,
                                  removeFunction: _vm.removeFile,
                                },
                                on: {
                                  "update:file": function ($event) {
                                    _vm.file = $event
                                  },
                                },
                              })
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        href: _vm.fileUrl,
                                        target: "_blank",
                                        color: "green darken-2",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Visualizar Licença de " +
                                          _vm._s(
                                            _vm.translateLicenseType(
                                              _vm.license.license_type
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "remove-license",
                                                    attrs: {
                                                      color: "red",
                                                      small: "",
                                                      icon: "",
                                                      flat: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFile()
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "\n                      mdi-close-circle-outline\n                    "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Remover Licença")])]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Número do Processo",
                              rules: [_vm.validationRules.required],
                            },
                            model: {
                              value: _vm.licenseData.process_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseData, "process_number", $$v)
                              },
                              expression: "licenseData.process_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Número da Licença",
                              rules: [_vm.validationRules.required],
                            },
                            model: {
                              value: _vm.licenseData.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseData, "number", $$v)
                              },
                              expression: "licenseData.number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "pr-1", attrs: { xs6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.orgs,
                                  rules: [_vm.validationRules.required],
                                  label: "Órgão Emissor",
                                },
                                model: {
                                  value: _vm.licenseData.emitter,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.licenseData, "emitter", $$v)
                                  },
                                  expression: "licenseData.emitter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "pl-1", attrs: { xs6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "full-width": "",
                                  },
                                  model: {
                                    value: _vm.showDatePicker,
                                    callback: function ($$v) {
                                      _vm.showDatePicker = $$v
                                    },
                                    expression: "showDatePicker",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "activator",
                                      value: _vm.licenseData.due_date,
                                      label: "Data de Validade",
                                      "persistent-hint": "",
                                      rules: [_vm.validationRules.required],
                                      readonly: "",
                                    },
                                    on: { focus: _vm.focusDatePicker },
                                    slot: "activator",
                                  }),
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      locale: "pt-br",
                                      min: _vm.format(new Date(), "YYYY-MM-DD"),
                                    },
                                    on: { change: _vm.setDate },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red darken-1", flat: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("\n            Cancelar\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "green darken-2",
                            loading: _vm.loading,
                            disabled: !_vm.valid,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("\n            Salvar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }