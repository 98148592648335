var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", row: "", "fill-height": "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.totalApprovedLicenses))]),
            _vm._v(" aprovada(s),\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.totalPendingLicenses))]),
            _vm._v(" pendente(s) e\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.totalrefusedLicenses))]),
            _vm._v(" recusada(s).\n    "),
          ]),
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "mt-4 mb-2 caption", attrs: { xs12: "" } },
            [
              _vm._v(
                "\n      Aprovadas (" +
                  _vm._s(_vm.totalApprovedLicenses) +
                  ")\n    "
              ),
            ]
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("license-list", { attrs: { list: _vm.approvedLicenses } })],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "mt-4 mb-2 caption", attrs: { xs12: "" } },
            [
              _vm._v(
                "\n      Recusadas (" +
                  _vm._s(_vm.totalrefusedLicenses) +
                  ")\n    "
              ),
            ]
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("license-list", {
                attrs: { refused: "true", list: _vm.refusedLicenses },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "mt-4 mb-2 caption", attrs: { xs12: "" } },
            [
              _vm._v(
                "\n      Pendentes (" +
                  _vm._s(_vm.totalPendingLicenses) +
                  ")\n    "
              ),
            ]
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("license-list", { attrs: { list: _vm.pendingLicenses } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }