var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("license-form-dialog", {
        attrs: { license: _vm.selectedLicense, dialog: _vm.licenseFormDialog },
        on: { closeLicenseFormDialog: _vm.closeLicenseFormDialog },
      }),
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  "hide-actions": "",
                  "no-data-text": "Nenhuma Licença",
                  headers: _vm.headers,
                  items: _vm.list,
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.translateLicenseType(props.item.license_type)
                            )
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.process_number)),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.number)),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.emitter)),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(_vm.formatDate(props.item.due_date))),
                        ]),
                        _vm.refused
                          ? _c(
                              "td",
                              { staticClass: "reason text-xs-center" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      left: "",
                                      color: "green darken-1",
                                      "max-width": "350",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c("span", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(props.item.reason)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("h3", { staticClass: "word-break" }, [
                                      _vm._v(_vm._s(props.item.reason)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "td",
                          { staticClass: "text-xs-center" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { bottom: "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g({ attrs: { icon: "" } }, on),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-dots-horizontal"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-tile",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDialog(props.item)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(
                                            "\n                    Atualizar Licença\n                  "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }