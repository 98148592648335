<template>
  <div>
    <license-form-dialog
      :license="selectedLicense"
      :dialog="licenseFormDialog"
      @closeLicenseFormDialog="closeLicenseFormDialog"
    />

    <v-layout>
      <v-flex xs12>
        <v-data-table
          hide-actions
          no-data-text="Nenhuma Licença"
          :headers="headers"
          :items="list"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td class="text-xs-center">{{ translateLicenseType(props.item.license_type) }}</td>
            <td class="text-xs-center">{{ props.item.process_number }}</td>
            <td class="text-xs-center">{{ props.item.number }}</td>
            <td class="text-xs-center">{{ props.item.emitter }}</td>
            <td class="text-xs-center">{{ formatDate(props.item.due_date) }}</td>
            <td v-if="refused" class="reason text-xs-center">
              <v-tooltip left color="green darken-1" max-width="350">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ props.item.reason }}</span>
                </template>
                <h3 class="word-break">{{ props.item.reason }}</h3>
              </v-tooltip>
            </td>
            <td class="text-xs-center">
              <v-menu
                bottom
                left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-tile
                    @click="openDialog(props.item)">
                    <v-list-tile-title>
                      Atualizar Licença
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import LicenseFormDialog from '@/components/person/licenses/LicenseFormDialog.vue';
import translateLicenseType from '@/mixins/translateLicenseType';

export default {
  name: 'LicenseList',
  props: ['list', 'refused'],
  mixins: [translateLicenseType],
  components: { LicenseFormDialog },
  data() {
    return {
      headers: [
        { text: 'Tipo', align: 'center', sortable: false },
        { text: 'Nº do Processo', align: 'center', sortable: false },
        { text: 'Nº da Licença', align: 'center', sortable: false },
        { text: 'Orgão Emissor', align: 'center', sortable: false },
        { text: 'Data de Validade', align: 'center', sortable: false },
        { text: 'Ações', align: 'center', sortable: false },
      ],
      selectedLicense: null,
      licenseFormDialog: false,
    };
  },
  mounted() {
    if (this.refused) {
      const reasonHeader = {
        text: 'Justificativa', align: 'center', sortable: false, width: 300,
      };
      this.headers.splice(5, 0, reasonHeader);
    }
  },
  methods: {
    openDialog(license) {
      this.selectedLicense = Object.assign({}, license);
      this.licenseFormDialog = true;
    },
    closeLicenseFormDialog() {
      this.selectedLicense = null;
      this.licenseFormDialog = false;
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    person() {
      return this.$store.getters['person/person'];
    },
  },
};
</script>

<style>
  .reason {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .word-break {
    word-break: break-word;
  }
</style>
