const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const STEP = 1024;

function format(value, power) {
  const stepTimePower = STEP ** power;
  return (value / stepTimePower).toFixed(2) + UNITS[power];
}

const fileSizeFilterMixin = {
  filters: {
    fileSize(value) {
      if (!value) {
        return '';
      }
      value = parseFloat(value, 10);
      let i = 0;
      for (i; i < UNITS.length; i += 1) {
        if (value < STEP ** i) {
          if (UNITS[i - 1]) {
            return format(value, i - 1);
          }
          return value + UNITS[i];
        }
      }
      return format(value, i - 1);
    },
  },
};

export default fileSizeFilterMixin;
