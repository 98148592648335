<template>
  <v-container>
    <v-layout align-center row fill-height>
      <v-flex xs4>
        <strong>{{totalApprovedLicenses}}</strong> aprovada(s),
        <strong>{{totalPendingLicenses}}</strong> pendente(s) e
        <strong>{{totalrefusedLicenses}}</strong> recusada(s).
      </v-flex>
    </v-layout>

    <v-layout column>
      <v-flex xs12 class="mt-4 mb-2 caption">
        Aprovadas ({{totalApprovedLicenses}})
      </v-flex>
      <v-flex xs12>
        <license-list :list="approvedLicenses"/>
      </v-flex>
      <v-flex xs12 class="mt-4 mb-2 caption">
        Recusadas ({{totalrefusedLicenses}})
      </v-flex>
      <v-flex xs12>
        <license-list refused="true" :list="refusedLicenses"/>
      </v-flex>
      <v-flex xs12 class="mt-4 mb-2 caption">
        Pendentes ({{totalPendingLicenses}})
      </v-flex>
      <v-flex xs12>
        <license-list :list="pendingLicenses"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import LicenseList from '@/components/person/licenses/LicenseList.vue';
import { isEmpty } from 'lodash';


export default {
  name: 'PersonLicenses',
  components: { LicenseList },
  mounted() {},
  data() {
    return {
      isEmpty,
    };
  },
  methods: {
    translateLicenseType(licenseType) {
      return {
        receiver: 'Destinador',
        transporter: 'Transportador',
        temporary_storage: 'Armazenador Temporário',
      }[licenseType];
    },
  },
  computed: {
    person() {
      return this.$store.getters['person/person'];
    },
    pendingLicenses() {
      return this.person.licenses.filter(license => ['waiting_approval', 'waiting_to_be_sent'].includes(license.status));
    },
    totalPendingLicenses() {
      return this.pendingLicenses.length;
    },
    approvedLicenses() {
      return this.person.licenses.filter(license => license.status === 'approved');
    },
    totalApprovedLicenses() {
      return this.approvedLicenses.length;
    },
    refusedLicenses() {
      return this.person.licenses.filter(license => license.status === 'not_approved');
    },
    totalrefusedLicenses() {
      return this.refusedLicenses.length;
    },
  },
};

</script>
