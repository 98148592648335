var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("input", {
            ref: "fileInput",
            staticStyle: { display: "none" },
            attrs: { accept: _vm.accept, required: "", type: "file" },
            on: { change: _vm.handleFileUpload },
          }),
          !_vm.isFileUploaded()
            ? _c("v-text-field", {
                attrs: {
                  "persistent-hint": "",
                  hint: _vm._f("fileSize")(_vm.tempFile.size),
                  label: "Selecionar arquivo",
                  readonly: "",
                  loading: _vm.isUploadingFile,
                  "error-messages": _vm.fileError,
                  "prepend-icon": "mdi-paperclip",
                },
                on: { "click:prepend": _vm.onFilePick, click: _vm.onFilePick },
                model: {
                  value: _vm.tempFile.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tempFile, "name", $$v)
                  },
                  expression: "tempFile.name",
                },
              })
            : _c("v-text-field", {
                staticClass: "fileLink",
                attrs: {
                  value: _vm.filename || "Arquivo",
                  "prepend-icon": "mdi-paperclip",
                  "append-icon": "mdi-close",
                  readonly: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.goToFileLink(_vm.file)
                  },
                  "click:append": function ($event) {
                    return _vm.removeFile()
                  },
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }